<template>
  <v-container style="max-width: 500px">
    <div class="count-wrapper">
      <StatusIndicator
        title="TODO"
        :value="remainingTasks"
        :numerator="completedTasks"
        :denominator="taskList.length"
        textColor="error--text"
        indicatorColor="success"
      />

      <!-- <StatusIndicator
        title="Done"
        :value="completedTasks"
        color="success--text"
      /> -->
      <TaskFilter />
    </div>

    <v-divider class="mt-4"></v-divider>

    <TaskSummary
      :doneCount="completedTasks"
      :totalCount="taskList.length"
      :remainingCount="remainingTasks"
    />

    <v-divider class="mb-4"></v-divider>

    <NewTaskInput />

    <TaskList />
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import StatusIndicator from "./StatusIndicator.vue";
import NewTaskInput from "./NewTaskInput.vue";
import TaskSummary from "./TaskSummary.vue";
import TaskList from "./TaskList.vue";
import TaskFilter from "./TaskFilter.vue";

const filters = {
  all: (tasks) => tasks,
  active: (tasks) => tasks.filter((task) => !task.done),
  completed: (tasks) => tasks.filter((task) => task.done),
};

export default {
  components: {
    StatusIndicator,
    NewTaskInput,
    TaskSummary,
    TaskList,
    TaskFilter,
  },
  data: () => ({}),
  computed: {
    ...mapGetters("todo", ["taskList", "activeFilter"]),

    // ...mapState({
    //   taskList: (state) => state.todo.taskList,
    // }),
    filteredTaskList() {
      return filters[this.activeFilter](this.taskList);
    },
    completedTasks() {
      return this.taskList.filter((task) => task.done).length;
    },
    remainingTasks() {
      return this.taskList.length - this.completedTasks;
    },
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.count-wrapper {
  display: flex;
  gap: 15px;
  flex-direction: row;
  height: 150px;
  justify-content: space-between;
  text-align: center;
}
</style>