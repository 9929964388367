import { render, staticRenderFns } from "./TaskFilter.vue?vue&type=template&id=5ba2b80a&scoped=true&"
import script from "./TaskFilter.vue?vue&type=script&lang=js&"
export * from "./TaskFilter.vue?vue&type=script&lang=js&"
import style0 from "./TaskFilter.vue?vue&type=style&index=0&id=5ba2b80a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ba2b80a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
installComponents(component, {VBtn,VBtnToggle})
