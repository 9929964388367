/* eslint-disable comma-dangle */
<template>
  <!-- <div id="contact-form page-margin" class="contact-form px-4" :class="[$vuetify.breakpoint.smAndDown ? 'sm-margin' : 'lg-margin']"> -->
  <div
    id="contact-form"
    class="contact-form page-margin"
    :class="[$vuetify.breakpoint.smAndDown ? 'sm-margin' : 'lg-margin']"
  >
    <div class="text-h4 mt-6 orange-fade bold">Contact Me</div>

    <v-form v-model="valid" class="form" ref="contactForm">
      <v-text-field
        required
        outlined
        type="email"
        label="Email"
        :rules="emailRules"
        :disabled="isSending"
        v-model="contact.email"
      ></v-text-field>
      <v-select
        required
        outlined
        label="Topic"
        :items="topics"
        :rules="topicRules"
        :disabled="isSending"
        v-model="contact.topic"
      ></v-select>

      <v-textarea
        counter
        outlined
        label="How can I help?"
        :rules="msgRules"
        :disabled="isSending"
        v-model="contact.message"
      ></v-textarea>

      <!-- <textarea
        name="message"
        v-model="contact.message"
        rows="4"
        placeholder="How can I help?"
      ></textarea> -->
      <!-- <button class="button" :disabled="isSending">Send</button> -->
      <v-btn
        tile
        x-large
        @click="onSubmit"
        :disabled="!valid"
        :loading="isSending"
        class="orange darken-3"
      >
        Send
      </v-btn>
    </v-form>
    <v-snackbar top right color="success" timeout="10000" v-model="snackbar">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
const apiUrl =
  "https://iiux33row4.execute-api.us-west-2.amazonaws.com/prod/contact-form";
const headers = {
  headers: {
    "x-api-key": "rZ07IZxgmf6vMJGvtalMJh5RrU84Vhn1C7MEeYyh",
  },
};

export default {
  name: "Contact",
  data: () => ({
    valid: false,
    contact: {
      email: "",
      topic: "",
      message: "",
    },
    topics: [
      "Need help creating a website",
      "Need consulting with a current project",
      "Following up on a previous request",
      "General question"
    ],
    isSending: false,
    emailRules: [
      (value) => !!value || "Email is required",
      (value) => (value || "").length <= 40 || "Max 40 characters",
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    ],
    topicRules: [(value) => !!value || "topic is required"],
    msgRules: [
      (v) => !!v || "message is required",
      (v) => (v || "").length <= 1000 || "Max 1000 characters"
    ],
    text: null,
    snackbar: false,
  }),
  methods: {
    /**
     * Clear the form
     */

    clearForm() {
      for (let field in this.contact) {
        this.contact[field] = "";
      }
      this.$refs.contactForm.reset();
    },

    showSuccessMsg() {
      this.isSending = false;
      this.text =
        "Thank you for reaching out!  I will be in contact with you soon.";
      this.snackbar = true;
    },
    /**
     * Handler for submit
     */
    onSubmit(evt) {
      evt.preventDefault();
      this.isSending = true;
      const form = { ...this.contact, };

      axios
        .post(apiUrl, form, headers)
        .then((response) => {
          // console.log(response);
          this.clearForm();
          this.showSuccessMsg();
        })
        .catch((e) => {
          // console.log(e);
          this.isSending = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.contact-form {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;

  &.sm-margin {
    margin-top: 90px;
  }

  &.lg-margin {
    margin-top: 150px;
  }

  .title {
    color: white;
    text-align: left;
    font-size: 35px;
    font-weight: 300;
  }

  .success-msg {
    position: absolute;
    top: 0;
    right: 0;
    background: #cff7cf;
    padding: 10px 15px;
    margin: 15px 20px 0 0;
    color: darkgreen;
  }

  .form {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-top: 30px;
  }
}
</style>
