<template>
  <!-- <div class="filter-group"> -->
  <v-btn-toggle
    tile
    mandatory
    borderless
    class="filter-group"
    v-model="visibleFilter"
  >
    <v-btn text class="filter-group-btn" value="all">
      All ({{ totalTaskCount }})
    </v-btn>
    <v-btn text class="filter-group-btn" value="todo">
      Todo ({{ totalTaskCount - doneTaskCount }})
    </v-btn>
    <v-btn text class="filter-group-btn" value="done">
      Done ({{ doneTaskCount }})
    </v-btn>
  </v-btn-toggle>
  <!-- </div> -->
  <!-- <v-radio-group v-model="toggle_exclusive">
    <v-radio label="All"></v-radio>
    <v-radio label="Done"></v-radio>
    <v-radio label="Todo"></v-radio>
  </v-radio-group> -->
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {
    ...mapGetters("todo", ["activeFilter", "totalTaskCount", "doneTaskCount"]),
    visibleFilter: {
      get() {
        return this.activeFilter;
      },
      set(value) {
        this.setActiveFilter(value);
      },
    },
  },
  methods: {
    ...mapActions("todo", ["setActiveFilter"]),
  },
};
</script>

<style scoped lang="scss">
$card-height: 150px;
$border-width: 3px;

// .v-btn-toggle {
//   flex-direction: column;
// }
.filter-group {
  // height: $card-height;
  display: grid;
  align-items: center;
  grid-template-columns: 100px;
  grid-template-rows: 1fr 1fr 1fr;
  // &-btn {
  //   border-radius: 0px;
  //   border: 0px;
  // }
  // align-items: end;
}
</style>