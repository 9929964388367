<template>
  <v-row class="my-1" align="center">
    <strong class="mx-4 info--text text--lighten-1">
      Tasks: {{ totalCount }}
    </strong>

    <v-divider vertical></v-divider>

    <strong class="mx-4 success--text text--lighten-1"
      >Done: {{ doneCount }}
    </strong>

    <v-divider vertical></v-divider>

    <strong class="mx-4 error--text text--lighten-1"
      >Todo: {{ remainingCount }}
    </strong>

    <v-spacer></v-spacer>

    <v-progress-circular :value="progress" class="mr-2"></v-progress-circular>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    totalCount: Number,
    doneCount: Number,
    remainingCount: Number,
  },
  data: () => ({}),
  computed: {
    // ...mapGetters("todo", ["taskList"]),

    // ...mapState({
    //   taskList: (state) => state.todo.taskList,
    // }),
    progress() {
      return (this.doneCount / this.totalCount) * 100;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>