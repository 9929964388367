<template>
  <div class="card-d">
    <!-- <div class="card-value" :class="color">{{ value }}</div>
    <div class="card-title">{{ title }}</div> -->
    <v-progress-circular
      :rotate="270"
      :size="150"
      :width="6"
      :value="progress"
      :color="indicatorColor"
    >
      <div class="card">
        <div v-if="this.remaining" class="card-value" :class="textColor">
          {{ this.remaining }}
        </div>
        <div v-if="!this.remaining" class="card-value" :class="textColor">
          <v-icon class="done-icon" color="success">mdi-check-all</v-icon>
        </div>
        <div class="card-title">{{ title }}</div>
      </div>
    </v-progress-circular>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  props: {
    title: String,
    value: Number,
    color: String,
    numerator: Number,
    denominator: Number,
    textColor: String,
    indicatorColor: String,
  },
  data: () => ({}),
  computed: {
    // ...mapGetters("todo", ["taskList"]),
    // // ...mapState({
    // //   taskList: (state) => state.todo.taskList,
    // // }),
    // completedTasks() {
    //   return this.taskList.filter((task) => task.done).length;
    // },
    // remainingTasks() {
    //   return this.taskList.length - this.completedTasks;
    // },
    progress() {
      console.log(this.numerator, this.denominator);
      return (this.numerator / this.denominator) * 100;
    },
    remaining() {
      return this.denominator - this.numerator;
    },
  },

  methods: {},
};
</script>

<style scoped lang="scss">
$card-height: 150px;
$border-width: 3px;

.card {
  // width: 150px;
  // height: 150px;
  // text-align: center;
  // border: 3px solid gray;
  // border-radius: 100px;
  overflow: hidden;

  &-value {
    font-size: 3em;
    line-height: 70px;
    padding-top: 25px;
    color: green;
    
  }

  &-title {
    font-size: 1.5em;
    text-transform: uppercase;
    // background: grey;
    height: 55px;
    color: gray;
  }

  .done-icon:before {
    font-size: 60px;
  }
}
</style>