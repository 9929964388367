<template>
  <v-text-field
    autofocus
    autocomplete="off"
    v-model="newTask"
    label="What are you working on?"
    @keydown.enter="createNewTask"
  >
    <template v-slot:append>
      <v-fade-transition>
        <v-icon v-if="newTask" @click="createNewTask"> mdi-plus-circle </v-icon>
      </v-fade-transition>
    </template>
  </v-text-field>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    task: Object,
    index: Number,
  },
  data: () => ({
    newTask: null,
  }),
  computed: {},
  methods: {
    ...mapActions("todo", ["toggleTodo", "createTask"]),
    createNewTask() {
      if (this.newTask.trim()) {
        this.createTask(this.newTask);
      }
      this.newTask = null;
    },
  },
};
</script>

<style scoped lang="scss"></style>