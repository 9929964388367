<template>
  <div>
    <v-divider v-if="index !== 0" :key="`${index}-divider`"></v-divider>

    <div :key="`${index}-${task.text}`" class="list-item">
      <div class="checkbox-wrapper">
        <v-checkbox
          class="checkbox"
          hide-details
          :color="(task.done && 'grey') || 'primary'"
          :input-value="task.done"
          @change="toggleTodo(task)"
        />
      </div>

      <div class="task">
        <div
          class="ml-4"
          v-show="!editing"
          :class="(task.done && 'grey--text') || 'primary--text'"
          @dblclick="editing = true"
        >
          {{ task.text }}
        </div>

        <v-text-field
          autofocus
          autocomplete="off"
          :value="task.text"
          :focus="editing"
          v-show="editing"
          @keyup.enter="doneEdit"
          @keyup.esc="cancelEdit"
          @blur="doneEdit"
        >
          <template v-slot:append>
            <v-fade-transition>
              <v-icon @click="doneEdit"> mdi-close-circle-outline </v-icon>
            </v-fade-transition>
          </template>
        </v-text-field>
      </div>

      <div class="action">
        <v-scroll-x-transition>
          <v-icon v-if="task.done" color="success"> mdi-check </v-icon>
        </v-scroll-x-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  props: {
    task: Object,
    index: Number,
  },
  data: () => ({
    editing: false,
  }),
  computed: {
    // ...mapGetters("todo", ["taskList"]),
    // // ...mapState({
    // //   taskList: (state) => state.todo.taskList,
    // // }),
    // completedTasks() {
    //   return this.taskList.filter((task) => task.done).length;
    // },
    // remainingTasks() {
    //   return this.taskList.length - this.completedTasks;
    // },
  },

  methods: {
    ...mapActions("todo", [
      "toggleTodo",
      "editTodo",
      "toggleTodo",
      "removeTodo"
    ]),
    doneEdit(e) {
      const value = e.target.value.trim();
      const { task } = this;
      if (!value) {
        this.removeTodo(task);
      } else if (this.editing) {
        this.editTodo({
          task,
          value,
        });
        this.editing = false;
      }
    },
    cancelEdit(e) {
      e.target.value = this.task.text;
      this.editing = false;
    },
  },
};
</script>

<style scoped lang="scss">
$card-height: 150px;
$border-width: 3px;

.list-item {
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  align-items: center;
  grid-template-rows: 50px;

  .checkbox-wrapper {
    padding-left: 15px;
    .checkbox {
      padding-top: 0px;
      margin-top: 0px;
    }
  }
}
</style>