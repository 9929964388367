<template>
  <v-card v-if="filteredTaskList.length > 0">
    <TaskItem v-for="(task, i) in filteredTaskList" :key="i" :task="task" :index="i" />
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import TaskItem from "./TaskItem.vue";

const filters = {
  all: todos => todos,
  todo: todos => todos.filter(todo => !todo.done),
  done: todos => todos.filter(todo => todo.done),
};

export default {
  components: { TaskItem },
  data: () => ({}),
  computed: {
    ...mapGetters("todo", ["taskList", "activeFilter"]),
    filteredTaskList() {
      console.log('>> in TaskList: ', this.activeFilter);
      return filters[this.activeFilter](this.taskList);
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
</style>